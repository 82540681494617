<template>
  <v-container fluid class="pa-0">
    <v-toolbar>
      <v-slide-group class="pa-2 pt-3">
        <v-slide-item class="mt-4">
          <v-btn v-if="isResponsableOrAdmin || hasPermission('add_entry')" @click="openNewEntryDrawer" color="primary">Nouvelle entrée</v-btn>
        </v-slide-item>
        <v-slide-item class="mt-6 ml-3">
          <v-checkbox v-model="displayContract" label="Afficher les entrées sous contrat"></v-checkbox>
        </v-slide-item>
        <v-slide-item class="mt-6 ml-3">
          <v-select v-model="filter_type" @change="displayContractWithTypeChange" label="Type de dépense" item-text="name" item-value="name" :items="types_depense"></v-select>
        </v-slide-item>
        <v-slide-item style="max-width: 200px" class="mt-6 ml-3">
          <v-select v-model="filter_depense" label="Catégorie de dépense" item-text="name" item-value="value" :items="statut_depense"></v-select>
        </v-slide-item>
        <v-slide-item class="mt-6 ml-3">
          <v-select v-model="filter_year" label="Année" :items="active_years"></v-select>
        </v-slide-item>
        <v-slide-item>
          <SiteTreeSelector v-model="filter_site_tree"/>
        </v-slide-item>
        <v-slide-item class="mt-6 ml-3">
          <v-btn color="red" text @click="resetFilters"><v-icon>mdi-trash-can</v-icon> Retirer les filtres</v-btn>
        </v-slide-item>
      </v-slide-group>
    </v-toolbar>
    <GeneralFinance/>
  </v-container>
</template>

<script>
import {AuthMixin} from "@/mixins/util/AuthMixin";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";
import {FinanceMixin} from "@/mixins/finance/FinanceMixin";
import GeneralFinance from "@/views/GeneralFinance";
import SiteTreeSelector from "@/views/SiteTreeSelector";

export default {
  name: "Finances",
  mixins: [AuthMixin, DateUtilMixin, DrawerMixin, FinanceMixin],
  components: {SiteTreeSelector, GeneralFinance},
}
</script>

