<template>
  <v-card elevation="0" flat style="max-height: 100vh" class="overflow-y-auto">
    <v-app-bar flat dark color="primary">
      <v-toolbar-title v-if="operation === 'create'">Nouvelle entrée financière</v-toolbar-title>
      <v-toolbar-title v-if="operation === 'update'">Mettre à jour l'entrée financière</v-toolbar-title>
    </v-app-bar>
    <v-card-text>
      <v-form ref="entryForm">
        <v-text-field label="Référence" v-model="entry.reference"></v-text-field>
        <v-menu max-width="100%" :close-on-content-click="false">
          <template v-slot:activator="{on, attrs}">
            <v-text-field v-on="on" v-bind="attrs"
                          :rules="[notNullNorEmpty]"
                          label="Date de gestion"
                          :value="getFormattedDate(entry.date_gestion)"></v-text-field>
          </template>
          <v-date-picker v-model="entry.date_gestion"></v-date-picker>
        </v-menu>
        <v-select label="Type de dépense"
                  :items="types_depense"
                  item-text="name"
                  :rules="[notNullNorEmpty]"
                  item-value="name"
                  v-model="entry.type_depense"></v-select>
        <v-text-field label="Prévisionnel" v-model="entry.previsionnel" type="number"></v-text-field>
        <v-text-field label="Réalisé" v-model="entry.realise" type="number"></v-text-field>
        <v-menu max-width="100%">
          <template v-slot:activator="{on, attrs}">
            <v-text-field v-on="on" v-bind="attrs"
                          label="Date de la facture"
                          :value="getFormattedDate(entry.date_facture)"
                          :rules="[(entry.reference_facture === '' || entry.date_facture !== null) || 'La date de facture doit être rempli si la réfence de facture est remplie.']"></v-text-field>
          </template>
          <v-date-picker v-model="entry.date_facture"></v-date-picker>
        </v-menu>
        <v-text-field label="Référence de la facture"
                      :rules="[(entry.date_facture === null || entry.reference_facture !== null) || 'La référence de facture doit être rempli si la date de facture est remplie.']"
                      v-model="entry.reference_facture"></v-text-field>
        <v-row justify="space-between">
          <span class="mt-7 mx-1"><v-icon>mdi-filter</v-icon>Filtrer par</span>
          <SiteTreeSelector v-model="assets_site_tree"/>
        </v-row>
        <p class="grey--text" v-if="entry.asset !== null && assets.length === 0">Équipement #{{ entry.asset }} :
          Chargement de la liste des équipements...</p>
        <v-autocomplete :items="filtered_assets" v-model="entry.asset" label="Équipement" item-text="name"
                        item-value="id"></v-autocomplete>
      </v-form>
    </v-card-text>
    <v-divider></v-divider>
    <v-card-actions>
      <v-btn color="primary" v-if="operation === 'create' || this.operation === 'reupdate'" text
             @click="createEntry">
        Créer l'entrée financière
      </v-btn>
      <v-btn color="primary" v-if="operation === 'update' || this.operation === 'reupdate'" text
             @click="updateEntry">
        Mettre à jour
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import ValidationRulesMixin from "@/mixins/util/ValidationRulesMixin";
import SiteTreeSelector from "@/views/SiteTreeSelector";

export default {
  name: "EntryForm",
  components: {SiteTreeSelector},
  mixins: [DateUtilMixin, ValidationRulesMixin],
  props: {
    operation: String,
    entryToUpdate: Object,
    entryAsset: {type: Number, default: null},
    entryPrestataire: {type: Number, default: null},
    entryIntervention: {type: Number, default: null},
    entryContract: {type: Number, default: null}
  },
  mounted() {
    this.assets_site_tree = [""];
    if (this.$store.getters["assets/allAssets"].length === 0) {
      this.$store.dispatch("assets/fetchAssets");
    }
    if (this.$store.getters["contracts/allContracts"].length === 0) {
      this.$store.dispatch("contracts/fetchContracts");
    }
  },
  data() {
    let empty_entry = {
      "reference": "",
      "date_gestion": null,
      "type_depense": null,
      "previsionnel": 0.00,
      "realise": 0.00,
      "date_facture": null,
      "reference_facture": "",
      "user_modified": null,
      "asset": this.entryAsset,
      "intervention": this.entryIntervention,
      "contract": null,
      "prestataire": null,
    };
    let updatable_entry = this.operation === "update" ? JSON.parse(JSON.stringify(this.entryToUpdate)) : null;
    return {
      types_depense: [
        {"name": "Budget"},
        {"name": "Dépense ponctuelle"},
        {"name": "Dépense sous contrat supplémentaire"},
        {"name": "Casse"},
      ],
      entry: this.operation === "update" ? updatable_entry : empty_entry,
    }
  },
  computed: {
    assets() {
      return this.$store.getters["assets/allAssets"];
    },
    filtered_assets() {
      return this.$store.getters["assets/getFilteredAssets"];
    },
    contracts() {
      return this.$store.getters["contracts/allContracts"];
    },
    assets_site_tree: {
      get() {
        return this.$store.getters["assets/getFilterSiteTree"];
      },
      set(value) {
        return this.$store.commit("assets/setFilterSiteTree", value)
      }
    },
  },
  methods: {
    cleanFields() {
      if (typeof this.entry.prestataire === "object") {
        this.entry.prestataire = this.entry.prestataire?.id;
      }
    },
    async createEntry() {
      if (this.$refs.entryForm.validate()) {
        this.cleanFields();
        await this.$store.dispatch("finance/postEntry", this.entry);
        await this.addCostToIntervention();
        await this.$store.dispatch("drawer/closeDialog");
        this.$emit('close-drawer');
        this.resetFields();
        await this.$store.dispatch("assets/fetchAssets");
        await this.$store.dispatch("messages/announceSuccess", "Entrée financière créée.")
      }
    },
    async updateEntry() {
      if (this.$refs.entryForm.validate()) {
        this.cleanFields();
        await this.$store.dispatch("finance/putEntry", this.entry);
        await this.$store.dispatch("drawer/closeDialog");
        this.$emit('close-drawer');
        this.resetFields();
        await this.$store.dispatch("assets/fetchAssets");
        await this.$store.dispatch("messages/announceSuccess", "Entrée financière créée.")
      }
    },
    async addCostToIntervention() {
      if(this.entryIntervention !== null) {
        let intervention = {id: this.entryIntervention, cout_achat: this.entry.realise};
        await this.$store.dispatch("interventions/patchIntervention", intervention);
      }
    },
    resetFields() {
      this.entry = {
        "balise": "",
        "date_gestion": null,
        "type_depense": null,
        "previsionnel": null,
        "realise": null,
        "date_facture": null,
        "reference_facture": "",
        "user_modified": null,
        "asset": null,
      };
    }
  }
}
</script>

<style scoped>

</style>