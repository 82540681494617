<template>
  <v-data-table loading-text="Chargement des entrées financières en cours..." :loading="!entriesLoaded" class="ma-2"
                dense :items="data_entries" item-key="id" :headers="headers">
    <template v-slot:item.asset="{ item }">
      <router-link v-if="item.asset !== null" :to="'/assets/' + item.asset">Voir l'équipement</router-link>
    </template>
    <template v-slot:item.contract="{ item }">
      <router-link v-if="item.contract !== null" :to="'/contracts/' + item.contract">Voir le contrat</router-link>
    </template>
    <template v-slot:item.intervention="{ item }">
      <router-link v-if="item.intervention !== null" :to="'/interventions/' + item.intervention">Voir l'intervention
      </router-link>
    </template>
    <template v-slot:item.prestataire="{ item }">
      <span v-if="item.prestataire !== null"><router-link
          :to="'/intervenants?name=' + encodeURIComponent(item.prestataire.raison_sociale)">{{
          item.prestataire.raison_sociale
        }}</router-link></span>
    </template>
    <template v-slot:item.date_gestion="{ item }">
      <span v-if="item.date_gestion !== null">{{ getFormattedDate(item.date_gestion) }}</span>
    </template>
    <template v-slot:item.date_created="{ item }">
      <span v-if="item.date_created !== null">{{ getFormattedDate(item.date_created) }}</span>
    </template>
    <template v-slot:item.date_modified="{ item }">
      <span v-if="item.date_modified !== null">{{ getFormattedDate(item.date_modified) }}</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon v-if="isResponsableOrAdmin || hasPermission('change_entry')"
              small
              class="mr-2"
              @click="editEntry(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
          small
          class="mr-2"
          @click="askDeleteEntry(item)"
      >
        mdi-trash-can-outline
      </v-icon>
      <v-dialog v-model="deleteDialog" v-if="isResponsableOrAdmin || hasPermission('delete_entry')" width="500">
        <v-card>
          <v-card-title class="headline red lighten-1 white--text">
            Supprimer l'entrée financière ?
          </v-card-title>
          <v-card-text class="body-1 mt-2">
            Confirmer la suppression de l'entrée financière ?
          </v-card-text>
          <v-card-actions>
            <v-btn @click="deleteEntry" text color="red">Supprimer l'entrée financière</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
  </v-data-table>
</template>
<script>
import {AuthMixin} from "@/mixins/util/AuthMixin";
import {FinanceMixin} from "@/mixins/finance/FinanceMixin";
import DateUtilMixin from "@/mixins/util/DateUtilMixin";
import {DrawerMixin} from "@/mixins/util/DrawerMixin";

export default {
  name: 'GeneralFinance',
  mixins: [AuthMixin, FinanceMixin, DateUtilMixin, DrawerMixin],
  data() {
    return {
      headers: [
        {'text': "ID", 'value': 'id'},
        {"text": "Référence", "value": "reference"},
        {"text": "Date de gestion", "value": "date_gestion"},
        {"text": "Type de dépense", "value": "type_depense"},
        {"text": "Prévisionnel", "value": "previsionnel"},
        {"text": "Réalisé", "value": "realise"},
        {"text": "Date de facture", "value": "date_facture"},
        {"text": "Référence de la facture", "value": "reference_facture"},
        {"text": "Équipement", "value": "asset"},
        {"text": "Intervention", "value": "intervention"},
        {"text": "Prestataire", "value": "prestataire"},
        {"text": "Contrat", "value": "contract"},
        {"text": "Date de création", "value": "date_created"},
        {"text": "Date de modification", "value": "date_modified"},
        {text: 'Actions', value: 'actions', sortable: false},
      ],
    }
  }
}
</script>
